import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";
import "./index.scss";

const ContactUsPopup = () => {
  const { t } = useTranslation();

  return (
    <Popup title="Contact-us" className="contact-us-popup">
      <p className="text1" style={{ textAlign: "center" }}>
        feedback@bigcity.in
      </p>
    </Popup>
  );
};

export default ContactUsPopup;
