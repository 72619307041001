import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";
import "./index.scss";

const PrivacyPopup = () => {
  const { t } = useTranslation();

  return (
    <Popup title="Privacy Policy" className="privacy-us-popup">
     <p style={{textAlign:"center"}}>
        coming soon
        </p>
    </Popup>
  );
};

export default PrivacyPopup;
