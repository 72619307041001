import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";

const HowTORedeemPopup = () => {
  const { t } = useTranslation();

  return (
    <Popup title="HOW TO REDEEM" className="howtoredeem-us-popup">
      <div style={{textAlign:"left"}}>

      <p style={{textAlign:"center", fontWeight:"bold"}}>
    Steps to redeem:
</p>
<p>
    1.Scan the QR code printed on the offer pack or visit <a href="https://www.cargilloffer.com" target="_blank">www.cargilloffer.com</a>
    {" "}to land on microsite
</p>
<p>
    2.Enter your name, mobile number state and batch code and click on submit
</p>
<p>
    3.Enter your OTP and click submit.
</p>
<p>
    4. All particpants will win assured MyGlamm offer and also stand a chance to
    win
</p>
<p>
    cashback, weekly and mega rewards.
</p>

      </div>
    </Popup>
  );
};

export default HowTORedeemPopup;
