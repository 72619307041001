import { useNavigate, useLocation } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout";
import React, { useEffect } from "react";
import { ROUTES } from "../../lib/consts";
import "./Completed.scss";
import tickimg from "../../assets/images/tickimg.png";
import { gtagTrackEvent, GA_EVENTS } from "../../lib/utils";
import footer from "../../assets/images/Bottom Creative.png";

import { useTranslation } from "react-i18next";
import store from "../../store/store";

const Completed = () => {
  const { state } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();



  
  // const reward = localStorage.getItem("reward");
  
  const { myGlamCode, isCashbackWinner, cashbackRewardCode }: any = store.getState().authReducer;
  console.log(myGlamCode, isCashbackWinner, cashbackRewardCode)
  
  useEffect(() => {
    // Track the Thank You event when the component mounts
    gtagTrackEvent(GA_EVENTS.Thank_You);
    if (!myGlamCode  && !cashbackRewardCode) {
      navigate(ROUTES.REGISTER);
    }
  }, [myGlamCode, cashbackRewardCode]);
  return (
    <>
      <MainLayout className="completed-page">
        <div className="congratulation-container">
          {state?.newUser ? (
            <>

            </>
          ) : (

            isCashbackWinner ?
              <>
                <>
                  <div className="congratulation-img">
                    {/*<img src={tickimg} />*/}
                    <p className="congrats-text">Congratulations!</p>
                  </div>
                  <p className="text12">
                    You have won cashback reward
                    <br />
                    {/* <p className="reward-text">{reward}</p> */}
                    <br />
                    <p className="text14">
                      Cashback reward code
                    </p>
                    <p className="reward-text">{cashbackRewardCode}</p>
                    <a className="link-text" href="https://www.cargillpromo.com" target="_blank">www.cargillpromo.com</a>
                    <p className="sms-text">Redeem it here</p>

                    <p className="yellow-text">
                      You have also won a My Glamm voucher!
                    </p>

                    <p className="text14">
                      The code and link will be sent via SMS.
                    </p>


                    <p className="yellow-text">
                      You also stand a chance to<br />
                      win cashback, weekly<br />
                      and mega rewards. <br />
                    </p>
                  </p>
                </>
              </> :
              <>
                <div className="congratulation-img">
                  {/*<img src={tickimg} />*/}
                  <p className="congrats-text">Congratulations!</p>
                </div>
                <p className="text12">
                  You have won MyGlamm<br />
                  {/*{reward}*/}
                  assured reward
                  <br />
                  {/* <p className="reward-text">{reward}</p> */}
                  <br />
                  <p className="text14">
                    MyGlamm reward code
                  </p>
                  <p className="reward-text">{myGlamCode}</p>
                  <p className="sms-text">Steps to redeem will be shared to you via SMS</p>
                  <p className="yellow-text">
                    You also stand a chance to<br />
                    win cashback, weekly<br />
                    and mega rewards. <br />
                  </p>
                </p>
              </>
          )}

          {/* <img src={footer} className="footer-img" alt="footer" /> */}
        </div>
      </MainLayout>
    </>
  );
};

export default Completed;
