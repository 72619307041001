import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../lib/consts";
import React, { useEffect } from "react";
import Popup from "../helpers/Popup";
import "../components/Popups/index.scss";

const TermsPage: React.FC = () => {
  const navigate = useNavigate();

  // useEffect(() => {
  //   // Check if the path contains '/how-to-redeem'
  //   if (location.pathname === "/how-to-redeem") {
  //     // Navigate to the HowToRedeemPage
  //     navigate("/how-to-redeem");
  //   }
  // }, [navigate]);
  return (
    <Popup
      title="Terms & Conditions"
      onClose={() => {
        navigate(ROUTES.HOME);
      }}
    >
      {/* <strong>
        <p dir="ltr" style={{ textAlign: "center" }}>
          Gemini Oil Contest Terms &amp; Conditions
        </p>
      </strong>
      <p dir="ltr">
        These terms and conditions apply to the "Gemini Oil" Contest for
        consumers (hereinafter referred to as the “<strong>Contest</strong>")
        sponsored and launched by Cargill India Private Limited (“
        <strong>Cargill</strong>”) having its corporate office at: 10th Floor,
        Wing 1&amp;3, AIPL Business Club, Sector – 62, Gurgaon – 122002 wherein
        consumers can participate by registering on the Contest website
        www.gemini.bigcityexperience.com (“<strong>Contest Website</strong>”).
      </p>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            The Contest is valid only in India, except the state of Tamil Nadu
            and any other State/Union Territory in India where prohibited by
            law. Offer is valid for products purchases made between 12.00:01 AM
            on 01/06/2024 to 11:59:59 PM on 31/07/2024 or till stock lasts,
            which ever is earlier (herein after referred as "Contest Period").
            Select batch codes from the promo contest (1st April,24 to 30th
            May,24) may be valid for the current promo period (1st June,24 to
            31st July,24).
          </p>
        </li>
      </ol>
      <ol start={2}>
        <li dir="ltr">
          <p dir="ltr">
            Employees, Trade Partners of Cargill and Premier Sales Promotions
            Private Limited and their respective immediate family members are
            not eligible to participate in the Contest. Consumers above the age
            of 18 years can participate in the Contest.
          </p>
        </li>
      </ol>
      <ol start={3}>
        <li dir="ltr">
          <p dir="ltr">During the Contest Period:</p>
        </li>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            15000 winners will be selected to win Rs. 10 Cashback Voucher
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            7200 winners will be selected to win Rs. 20 Cashback Voucher
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            2400 winners will be selected to win Rs. 50 Cashback Voucher
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            600 winners will be selected to win Rs. 100 Cashback Voucher
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">8 winners will be selected to win 1gm Gold Coin</p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            1 winner will be selected to win a Scooter (ex-showroom price)
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            All valid participants of the contest shall win an assured Ajio
            Voucher.
          </p>
        </li>
      </ol>
      <ol start={4}>
        <li dir="ltr">
          <p dir="ltr">
            By participating in this Contest, Participants fully and
            unconditionally agree to and accept these Terms and Conditions
            available on the Contest Website at
            www.gemini.bigcityexperience.com.
          </p>
        </li>
      </ol>
      <ol start={5}>
        <li dir="ltr">
          <p dir="ltr">
            Cargill’s decisions will be final and binding in all matters related
            to the Contest.
          </p>
        </li>
      </ol>
      <ol start={6}>
        <li dir="ltr">
          <p dir="ltr">
            Cargill reserves the right to exclude any person from the Contest on
            grounds of misconduct or criminal record or for any other reasons,
            as it may deem fit and proper. No communication in this regard will
            be entertained by Cargill
          </p>
        </li>
      </ol>
      <ol start={7}>
        <li dir="ltr">
          <p dir="ltr">
            Cargill reserves the right to terminate, modify or extend the
            Contest and/or Contest Period and/or deletion or addition of any of
            the terms and conditions of the Contest, at any time at its absolute
            discretion, without advance notice and / or assigning any reason
            thereof.
          </p>
        </li>
      </ol>
      <ol start={8}>
        <li dir="ltr">
          <p dir="ltr">
            Participants unconditionally and irrevocably consent to and permit
            Cargill and/or Premier Sales Promotions Private Limited to
            call/message shortlisted winners by virtue of them having
            voluntarily participated in the Contest
          </p>
        </li>
      </ol>
      <ol start={9}>
        <li dir="ltr">
          <p dir="ltr">
            The Contest shall run on the select stock keeping units (“
            <strong>SKU’s</strong>”) of Cargill’s Gemini Oil. The Contest is
            applicable only to said SKU’s/promo packs and is not applicable to
            other packs.
          </p>
        </li>
      </ol>
      <ol start={10}>
        <li dir="ltr">
          <p dir="ltr">
            Any participant can participate maximum twice during the Contest
            Period from a mobile number.
          </p>
        </li>
      </ol>
      <ol start={11}>
        <li dir="ltr">
          <p dir="ltr">
            Participants will be required to register on the Contest Website by
            visiting the website: www.gemini.bigcityexperience.com.
          </p>
        </li>
      </ol>
      <ol start={12}>
        <li dir="ltr">
          <p dir="ltr">
            Participants will enter a unique code (code will be mentioned on
            Gemini Oil promotion packs/SKU’s as mentioned above purchased by the
            Consumer from the market) along with other details to submit their
            registration. The unique code is valid only if stock purchased from
            our authorized channel partner. In case of duplicate unique code,
            all the participants with same unique code will be disqualified from
            participation in the Contest.
          </p>
        </li>
      </ol>
      <ol start={13}>
        <li dir="ltr">
          <p dir="ltr">
            Cargill and/or Premier Sales Promotions Private Limited shall not be
            responsible for:
          </p>
        </li>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">For any SPAM generated messages;</p>
        </li>
      </ol>
      <ol start={2}>
        <li dir="ltr">
          <p dir="ltr">
            For the Operator Code not being displayed on the user's mobile
            phones;
          </p>
        </li>
      </ol>
      <ol start={3}>
        <li dir="ltr">
          <p dir="ltr">For any SMS message delivery failures;</p>
        </li>
      </ol>
      <ol start={4}>
        <li dir="ltr">
          <p dir="ltr">
            Any lost, late or misdirected computer transmission or network,
            electronic failures or any kind of any failure to receive entries
            owing to transmission failures or due to any technical reason;
          </p>
        </li>
      </ol>
      <ol start={5}>
        <li dir="ltr">
          <p dir="ltr">
            Non-receipt of call/message due to registration of a Participant to
            the DND of the telecom provider/ National Do Not Call Registry/ any
            other restriction which a Participant has specifically requested for
            not receiving messages for the specific campaign;
          </p>
        </li>
      </ol>
      <ol start={6}>
        <li dir="ltr">
          <p dir="ltr">Other conditions beyond its control.</p>
        </li>
      </ol>
      <ol start={14}>
        <li dir="ltr">
          <p dir="ltr">
            Any Participant can win a maximum of 2 prize(s) during the Contest
            Period basis a combination of unique mobile number, valid batch
            code, unique permanent account number, unique Aadhaar number i.e.
            any participant can win a maximum of one cashback voucher and one
            Scooter prize. Any participant who has won a cashback prize or
            Activa prize shall not be eligible to win gold coin prize.
          </p>
        </li>
      </ol>
      <ol start={15}>
        <li dir="ltr">
          <p dir="ltr">
            The prize cannot be transferred, exchanged, or redeemed for cash. In
            the event that a Winner does not take the prize in the time and
            manner stipulated, then the prize will be forfeited and cash will
            not be awarded in lieu of the prize. Any unclaimed prize is
            not-transferable, non-refundable and non-redeemable for other goods
            or services.
          </p>
        </li>
      </ol>
      <ol start={16}>
        <li dir="ltr">
          <p dir="ltr">
            Cargill and/or Premier Sales Promotions Private Limited shall not be
            liable for any loss or damage of the prize due to act of god,
            governmental action, force majeure circumstances and / or any other
            reasons which are beyond the control of Cargill and/or Premier Sales
            Promotions Private Limited, and under no circumstances, Cargill
            and/or Premier Sales Promotions Private Limited shall be liable to
            pay any compensation whatsoever for such losses.
          </p>
        </li>
      </ol>
      <ol start={17}>
        <li dir="ltr">
          <p dir="ltr">
            Once the Winners are selected, Premier Sales Promotions Private
            Limited shall process the distribution of prizes to the Winners.
          </p>
        </li>
      </ol>
      <ol start={18}>
        <li dir="ltr">
          <p dir="ltr">
            The cashback voucher winners will receive their cashback reward code
            and a weblink to claim their cashback prize.
          </p>
        </li>
      </ol>
      <ol start={19}>
        <li dir="ltr">
          <p dir="ltr">
            For gold coin &amp; Scooter Prize winners: Premier Sales Promotions
            Private Limited shall reach out to the winner. In the event the
            shortlisted Winner's number is busy, unreachable, or he/she does not
            attend the call, upto two (2) more attempts will be made to reach
            the Participant on their winning mobile number. If even on the third
            attempt, the Participant does not attend the call, or is
            unreachable, the Participant's participation in the Contest comes to
            an end and the Participant's entry shall stand cancelled/forfeited
            and the Participant becomes ineligible to receive the prize.
          </p>
        </li>
      </ol>
      <ol start={20}>
        <li dir="ltr">
          <p dir="ltr">
            In case of a positive response from the shortlisted Winner, each
            such shortlisted Winner will be required to send the following
            documents and information:
          </p>
        </li>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            Copy of Address proof (Electricity bill or Mobile Bill)
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">Copies of Aadhaar Card &amp; PAN Card mandatory</p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            No objection/claim certificate from the Winner after claiming prize
          </p>
        </li>
      </ol>
      <p dir="ltr">
        The above (i) to (iii) are referred to as the “Participation Package”
        for the Winner. A Participant agrees to share the Participant Package
        with Cargill and/or Premier Sales Promotions Private Limited and/or
        their respective authorised representatives for the purpose of claiming
        the prize. The completed Participation Package needs to be sent by email
        to Premier Sales Promotions Private Limited at:
        <a href="mailto:win@bigcity.in">win@bigcity.in</a>
        within 2 days of the call made/message sent by Premier Sales Promotions
        Private Limited and/or its authorised representative to the selected
        Winner.
      </p>
      <ol start={21}>
        <li dir="ltr">
          <p dir="ltr">
            Upon receipt of the Participation Package, Cargill and/or Premier
            Sales Promotions Private Limited shall scrutinize all documents. If
            all the documents submitted are in order and valid then a
            Participant will be treated as a Confirmed Winner.
          </p>
        </li>
      </ol>
      <ol start={22}>
        <li dir="ltr">
          <p dir="ltr">
            No prize will be awarded if the information presented/submitted by
            the Participant(s) at the time of entering the Contest is not
            factually correct.
          </p>
        </li>
      </ol>
      <ol start={23}>
        <li dir="ltr">
          <p dir="ltr">
            The responsibility of ensuring that the Participation Package
            reaches the specified e-mail Id above lies solely with the
            Participants.
          </p>
        </li>
      </ol>
      <ol start={24}>
        <li dir="ltr">
          <p dir="ltr">
            In the event of death of the Confirmed Winner, no claim from the
            nominee or his/her legal heirs of the Confirmed Winner shall be
            entertained for receiving the prize.
          </p>
        </li>
      </ol>
      <ol start={25}>
        <li dir="ltr">
          <p dir="ltr">
            The Winners must, at the request of CARGILL INDIA PRIVATE LIMITED
            and/or its authorised representatives, participate in all
            promotional activity (such as publicity and photography) surrounding
            the winning of the prize, free of charge, and they consent to
            Cargill using their name and image in promotional material.
          </p>
        </li>
      </ol>
      <ol start={26}>
        <li dir="ltr">
          <p dir="ltr">
            All right, title and interest, including but not limited to the
            intellectual property rights, in the promotional material(s) and in
            any and all responses received shall vest solely and exclusively
            with Cargill at all times. Cargill shall be entitled to use the
            responses received or any information in connection with the entry
            in any media for future promotional, marketing, publicity and any
            other purpose, without any permission and or payment to the
            Participant.
          </p>
        </li>
      </ol>
      <ol start={27}>
        <li dir="ltr">
          <p dir="ltr">
            All rights including the intellectual property rights in the
            material submitted in connection with the Contest (whether written,
            audio, electronic or visual form, or a combination of those) or
            rights in any photographs, video and/or film footage and/or audio
            recording taken of Participants shall be owned by and belong to
            Cargill, and if these are not owned or doesn’t belong to Cargill,
            Participants unconditionally and irrevocably assigned them to
            Cargill exclusively.
          </p>
        </li>
      </ol>
      <ol start={28}>
        <li dir="ltr">
          <p dir="ltr">
            All the participants shall be required to ensure that the mobile
            number(s), e-mail address and/or other details provided by them via
            the website or otherwise are true, accurate and in use. Any
            liability, consequence or claim arising on account of any incorrect
            or outdated information shall solely be borne by the
            affected/concerned participants. Cargill shall not be liable for
            failure of the winner to redeem the prize owing to incorrect
            details.
          </p>
        </li>
      </ol>
      <ol start={29}>
        <li dir="ltr">
          <p dir="ltr">
            Cargill or it’s partner agency shall at no time be required to
            verify the accuracy and/or correctness of the information so
            provided by the participants. Further, you are responsible for
            maintaining the confidentiality of your mobile phones, e-mail
            accounts and passwords.
          </p>
        </li>
      </ol>
      <ol start={30}>
        <li dir="ltr">
          <p dir="ltr">
            Cargill or it’s partner agency accepts no responsibility for any tax
            implications that may arise from the prize winnings. Tax is to be
            borne by the winner. Winners will have to bear incidental costs if
            any, that may arise for redemption of the Prizes.
          </p>
        </li>
      </ol>
      <ol start={31}>
        <li dir="ltr">
          <p dir="ltr">
            In no event shall the maximum liability of Premier Sales Promotions
            Pvt Ltd or Cargill exceed the value of the prize.
          </p>
        </li>
      </ol>
      <p dir="ltr">
        <strong>Reward Terms &amp; Conditions</strong> <br />
      </p>
      <p dir="ltr">Ajio Voucher Terms &amp; Conditions</p>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            The offer is applicable only on the select styles available on this
            link: <a href="https://bit.ly/4bRB8nm">https://bit.ly/4bRB8nm</a>
            and not on entire Ajio products
          </p>
        </li>
      </ol>
      <ol start={2}>
        <li dir="ltr">
          <p dir="ltr">
            Ajio voucher can be redeemed on Ajio till 31st May 2024 to get Flat
            Rs 200 off on min transaction of Rs 999
          </p>
        </li>
      </ol>
      <ol start={3}>
        <li dir="ltr">
          <p dir="ltr">
            The maximum discount that can be availed from this coupon is Rs. 200
          </p>
        </li>
      </ol>
      <ol start={4}>
        <li dir="ltr">
          <p dir="ltr">
            The user must sign up/login on AJOI before applying the coupon code
            in order to avail the offer
          </p>
        </li>
      </ol>
      <ol start={5}>
        <li dir="ltr">
          <p dir="ltr">
            If the coupon code is not visible due to improper scratch, Cargill
            shall have no liability
          </p>
        </li>
      </ol>
      <ol start={6}>
        <li dir="ltr">
          <p dir="ltr">
            Offer cannot be combined with any other promotion offered by AJIO
          </p>
        </li>
      </ol>
      <ol start={7}>
        <li dir="ltr">
          <p dir="ltr">
            AJIO reserves the right to cancel the orders placed by Eligible
            Users if the transaction is found to be of fraudulent nature
          </p>
        </li>
      </ol>
      <ol start={8}>
        <li dir="ltr">
          <p dir="ltr">
            Coupon may be redeemed only once per user and cannot be assigned and
            is non-negotiable and non-encashable
          </p>
        </li>
      </ol>
      <ol start={9}>
        <li dir="ltr">
          <p dir="ltr">
            In case of any issues or questions relating to the offer, users may
            contact AJIO at
            <a href="https://www.ajio.com/selfcare">
              https://www.ajio.com/selfcare
            </a>
          </p>
        </li>
      </ol>
      <p dir="ltr">
        <br />
        <strong>Bike Gift Voucher Terms &amp; Conditions</strong>
      </p>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            “Bike gift voucher” shall mean and imply a base model of preselected
            bike of worth upto Rs 100,000, at the ex-showroom Price.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            “Price” shall mean the ex-showroom basic price of the Bike and not
            the “on-road” price and specifically excludes all taxes,
            registration costs, insurance, levies, etc. (as may be applicable).
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            The bike voucher holder to bear the cost of Road Tax, Insurance,
            Registration, and any other incidental costs that occur other that
            ex-showroom price.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            All forms of taxes, including gift tax, income tax road tax, levies
            or other taxes, registration charges, insurance, etc. will be borne
            by the winner.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            The prizes are personal to the winners and are not transferrable and
            cannot be sold under any circumstances. Prizes cannot be exchanged
            for cash or kind. No request for a change in make, model, colour,
            and specifications etc of the car will be entertained.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            The delivery time will vary depending on the availability of the
            Bike. On receipt of complete Winners information and completion of
            all formalities, the approximate delivery time will be communicated
            to the confirmed winners. Goods once delivered cannot be replaced
            under any circumstances.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Cargill or Premier Sales Promotions Pvt. Ltd. do not offer any
            product warranty. In case of any defect whether related to
            manufacturing or otherwise, winner participant will have to resolve
            the same directly through manufacturer and all claims towards
            warranty need to be taken up with the manufacturer/distributor
            directly.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            In case the Bike is not available in the winner city, Premier Sales
            Promotions Pvt. Ltd. will arrange the availability of the in the
            nearest city wherever the bike dealership is available. All costs
            associated with traveling/accommodation or any other cost which
            might incur will be borne by the winner.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            There is no cash alternative for the Prize and the same cannot be
            exchanged for cash at any point nor transferred to someone else.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Cargill reserves the right to substitute the Prize offered at any
            point of time with rewards of equal value.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            All warranties of any kind whatsoever, whether expressed or implied,
            are hereby expressly disclaimed by, Cargill including but not
            limited to, meeting of the participants‟ requirements or
            aspirations, timeliness, security, un-interestedness, error-free,
            the results or reliability of the promotion, or the delivery,
            quality, quantity, merchantability, fitness for use or
            non-infringement in respect of any goods, services, emoluments,
            benefits or awards acquired or obtained through the promotion.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Delivery of the bike will be at the dealer showroom, all the costs
            and expenses for collecting the prize will be borne solely by the
            winner and Cargill or it’s partner agency shall not entertain any
            claim, whatsoever. The winner is solely responsible for any other
            expenses related to the acceptance and use of the offer.
          </p>
        </li>
      </ol>
      <ol start={13}>
        <li dir="ltr">
          <p dir="ltr">
            Cargill has no liability with respect to the Bike except for handing
            over the possession at dealer outlet. Participant/winner assumes all
            liabilities w.r.t. the bike once the same is taken over by him/her
            and the Participant/winner shall complete all legal formalities and
            compliances of his own.
          </p>
        </li>
      </ol>
      <strong>
        <p dir="ltr">Gold Coin Terms &amp; Conditions</p>
      </strong>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            All forms of taxes, including gift tax, income tax etc. if
            applicable will be borne by the winner.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Winner participants will be called to fixed place for hand over of
            the Gold Coin. Cargill shall not be liable to pay for the expenses
            in commuting to such fixed place and which shall be solely borne by
            winner participant.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            There is no cash alternative for the Prize and the same cannot be
            exchanged for cash at any point nor transferred to someone else.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Cargill reserves the right to substitute the Prize offered at any
            point of time with rewards of equal value.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            All the costs and expenses for collecting the prize will be borne
            solely by the winner and CARGILL INDIA PRIVATE LIMITED or it’s
            partner agency shall not entertain any claim, whatsoever. The winner
            is solely responsible for any other expenses related to the
            acceptance and use of the prize.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            No requests or claims shall be entertained by Cargill w.r.t. Gold
            Coins.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Cargill does not provide any sort of warranty or guarantee with
            respect to Gold Coin. Winner participants understand and acknowledge
            that the Gold Coin has been handed over by Cargill on “As Is Where
            Is” basis and as received by Cargill.
          </p>
        </li>
      </ol>
      <p dir="ltr">
        <br />
        <strong>Cashback Voucher Terms &amp; Conditions</strong>
      </p>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            Cashback Voucher entitles the winner to avail cashback on their UPI
            or NEFT. The user will be responsible for their choice of the
            cashback transfer method.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Any user purchasing a promotional product pack shall stand a chance
            to receive a cashback of an amount between Rs 10/- to Rs 100/-
            (“Cashback/Voucher Amount”).
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            The user will be responsible for entering details such as UPI ID,
            etc. Cargill or Premier Sales Promotions Pvt Ltd. will not be
            responsible for any incorrect entries or details on the promotion
            website. No change in details will be entertained once you have
            submitted the booking.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Premier Sales Promotions Private Limited or Cargill will not be
            responsible for cashback failures due to any failure at the payment
            gateway or payment processing partner end.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            The Payment Processing Partner holds the right to refuse
            transactions deemed fraudulent as per their policies.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            In no event shall Cargill be held liable for any issues relating to
            the redemption of the voucher/unique code including redemption
            requests not being honoured by the payment processing partner.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            The payment processing/wallet partner reserves the right to restrict
            cashback for any account with suspicious behaviour or invalid
            details/credentials. The wallet partner has the right to ask for
            documents to prove the retailer/user's identity.
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Once user can avail benefit of maximum of 2 cashback vouchers
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            If the coupon code is not visible due to improper scratch, Cargill
            shall have no liability
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Offer cannot be combined with any other promotion offered
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            The voucher is valid only if stock purchased from our authorized
            trade partner
          </p>
        </li>
        <li dir="ltr">
          <p dir="ltr">
            Voucher may be redeemed only once per user and cannot be assigned
            and is non-negotiable.
          </p>
        </li>
      </ol>
      <ol start={2}>
        <li dir="ltr">
          <strong>
            {" "}
            <p dir="ltr">General Terms &amp; Conditions</p>
          </strong>
        </li>
      </ol>
      <ol>
        <li dir="ltr">
          <p dir="ltr">
            Each Participant represents and warrants that he/she is legally
            competent to enter into binding contracts under applicable laws. By
            taking part and/or entering into the Contest the Participant
            warrants that all information provided by the Participant regarding
            Participant's name, age, state, city, address, phone number, etc.,
            is true, correct, accurate and complete.
          </p>
        </li>
      </ol>
      <ol start={2}>
        <li dir="ltr">
          <p dir="ltr">
            CARGILL INDIA PRIVATE LIMITED and/or Premier Sales Promotions
            Private Limited shall not be accountable/liable for any
            disruptions/stoppages/interruptions or cancellation of the Contest
            due to any government restrictions.
          </p>
        </li>
      </ol>
      <ol start={3}>
        <li dir="ltr">
          <p dir="ltr">
            Failure to exercise or delay in exercising a right or remedy
            provided hereunder or by law does not constitute a waiver of the
            right or remedy or waiver of other rights or remedies on the part of
            Cargill and/or Premier Sales Promotions Private Limited.
          </p>
        </li>
      </ol>
      <ol start={4}>
        <li dir="ltr">
          <p dir="ltr">
            If a Participant is dissatisfied with the Contest or the Contest
            rules and/or any content or any requirements of the Contest form,
            the materials available related thereto, or with these General Terms
            &amp; Conditions and/or any terms and conditions associated with the
            Contest, his/her sole and exclusive remedy is to not participate in
            the Contest.
          </p>
        </li>
      </ol>
      <ol start={5}>
        <li dir="ltr">
          <p dir="ltr">
            Subject to eligibility criteria of the Contest, if a person chooses
            to access the Contest Website from outside India, he/she shall do so
            on his/her own initiative and shall be responsible for compliance
            with applicable local laws of the country.
          </p>
        </li>
      </ol>
      <ol start={6}>
        <li dir="ltr">
          <p dir="ltr">
            Participation in the Contest is purely voluntary and the same shall
            be construed as an acceptance of the terms and conditions stipulated
            herein.
          </p>
        </li>
      </ol>
      <ol start={7}>
        <li dir="ltr">
          <p dir="ltr">
            Validity of prize cannot be extended, OR new prize cannot be
            provided against the expired/unused codes.
          </p>
        </li>
      </ol>
      <ol start={8}>
        <li dir="ltr">
          <p dir="ltr">
            All disputes arising out of or in connection to the Contest are
            subject to exclusive jurisdiction of the courts in Gurgaon, India
            only.
          </p>
        </li>
      </ol> */}
    </Popup>
  );
};

export default TermsPage;
