import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";
import "./index.scss";

const TermsPopup = () => {
  const { t } = useTranslation();

  return (
    <Popup title="Terms & Conditions">
      <p>
      <strong>Cargill Scan  win Contest 2024 -Terms  Conditions</strong>
      </p>
      
      
      <p>
        These terms and conditions apply to the Cargill’s scan and win contest 2024
        (hereinafter referred to as the <strong>“Contest"</strong>) sponsored and launched by Cargill India Private Limited
        <strong>(“Cargill”)</strong> having its corporate office at: 10 th Floor, Wing 13, AIPL Business Club, Sector – 62,
        Gurgaon – 122002 wherein consumers
        can participate by registering on the contest website www.cargilloffer.com
        <strong>(“Contest Website”).</strong>
      </p>
      
      <p>
        a. 1.Scan the QR code printed on the offer pack or visit
        www.cargilloffer.com to land on
        microsite
      </p>
      
      <p>
        2.Enter your name, mobile number state and batch code and click on submit
      </p>
      
      <p>
        3.Enter your OTP and click submit
      </p>
      
      <p>
        4.All particpants will win assured MyGlamm offer and also stand a chance to
        win Cahback,weekly and mega rewards.
      </p>
      
      <p>
        b. The Contest is valid only in India, except in the state of Tamil Nadu and
        any other State/Union Territory in India where participation in such Contest is
        prohibited by law.
      </p>
      
      <p>
        Contest is open for those consumers who had purchased Cargill’s product
        between 12.00:01 AM on 01/10/2024 to 11:59:59 PM on 31/01/2025 or till stock lasts,
        whichever
        is earlier (hereinafter referred as <strong>"Contest Period"</strong>).
      </p>
      
      <p>
        c. Employees, trade partners, channel partners, distributors, retailers of
        Cargill and Premier
      </p>
      
      <p>
        Sales Promotions Private Limited and their respective immediate family
        members are not
      </p>
      
      <p>
        eligible to participate in the Contest. Only the consumers who has purchased
        the Cargill
      </p>
      
      <p>
        Products from authorized retailer of Cargill and who are above the age of 18
        years can
      </p>
      
      <p>
        participate in the Contest.
      </p>
      
      <p>
        d. During the Contest Period:
      </p>
      
      <p>
        (i) 30000 winners will be selected to win Rs. 10 Cashback Voucher
      </p>
      
      <p>
        (ii) 10800 winners will be selected to win Rs. 20 Cashback Voucher
      </p>
      
      <p>
        (iii) 1800 winners will be selected to win Rs. 50 Cashback Voucher
      </p>
      
      <p>
        (iv) 600 winners will be selected to win Rs. 100 Cashback Voucher
      </p>
      
      <p>
        (v) 8 winners will be selected to win Rs 9999 Cashback Voucher
      </p>
      
      <p>
        (vi) 1 winner will be selected to win a Rs 100,000 Cashback Voucher
      </p>
      
      <p>
        (vii) All valid participants of the contest shall win an assured MyGlamm
        Voucher.
      </p>
      
      <p>
        The cashback amount will be
      </p>
      
      <p>
        e. By participating in this Contest, Participants fully and unconditionally
        agree to and
      </p>
      
      <p>
        accept these Terms and Conditions available on the Contest Website at
      </p>
      
      <p>
        www.cargilloffers.com
      </p>
      
      <p>
        f. Cargill’s decisions will be final and binding in all matters related to
        the Contest.
      </p>
      
      <p>
        g. Cargill reserves the right to exclude any person from the Contest on
        grounds of
      </p>
      
      <p>
        misconduct or criminal record or for any other reasons, as it may deem fit
        and proper. No
      </p>
      
      <p>
        communication in this regard will be entertained by Cargill
      </p>
      
      
      <p>
        h. Cargill reserves the right to terminate, modify or extend the Contest
        and/or Contest
      </p>
      
      <p>
        Period and/or deletion or addition of any of the terms and conditions of the
        Contest, at
      </p>
      
      <p>
        any time at its absolute discretion, without advance notice and / or
        assigning any reason
      </p>
      
      <p>
        thereof.
      </p>
      
      <p>
        i. Participants unconditionally and irrevocably consent to and permit
        Cargill and/or Premier
      </p>
      
      <p>
        Sales Promotions Private Limited to call/message shortlisted winners by
        virtue of them
      </p>
      
      <p>
        having voluntarily participated in the Contest
      </p>
      
      <p>
        j. The Contest shall run on the select products of Cargill’s (“Cargill
        Product”) :
      </p>
      
      
      <p>
         Nature Fresh Refined Soyabean Oil 1 Litre Pouch
      </p>
      
      <p>
         Nature Fresh Refined Soyabean Oil 500 ml Pouch
      </p>
      
      <p>
         Nature Fresh Superior MP Atta 10 kg Pack
      </p>
      
      <p>
         Nature Fresh Shuddh Chakki Atta 10 kg Pack
      </p>
      
      <p>
         Nature Fresh Shuddh Chakki Atta 5 kg Pack
      </p>
      
      <p>
         Nature Fresh Superior MP Atta 5 kg Pack
      </p>
      
      <p>
         Gemini Vanaspati 1 Litre Pouch
      </p>
      
      <p>
         Sunflower Brand Vanaspati 1 Litre Pouch
      </p>
      
      
      <p>
        k. The Contest is applicable only to aforesaid <strong>"Cargill Products"</strong> and is not
        applicable to other
      </p>
      
      <p>
        packs.
      </p>
      
      <p>
        l. Any participant can participate maximum twice during the Contest Period
        from a mobile
      </p>
      
      <p>
        number.
      </p>
      
      <p>
        m. Participants will be required to register on the Contest Website by
        visiting the website:
      </p>
      
      <p>
        www.cargilloffer.com
      </p>
      
      <p>
        n. Participants will enter a batch code (code will be mentioned on the
        promotion
      </p>
      
      <p>
        packs/SKU’s as mentioned above purchased by the Consumer from the market)
        along
      </p>
      
      <p>
        with other details to submit their registration. The batch code is valid
        only if stock
      </p>
      
      <p>
        purchased from our authorized channel partner.
      </p>
      
      <p>
        o. Cargill and/or Premier Sales Promotions Private Limited shall not be
        responsible for:
      </p>
      
      <p>
        (i) For any SPAM generated messages;
      </p>
      
      <p>
        (ii) For the Operator Code not being displayed on the user&amp;#39;s mobile
        phones;
      </p>
      
      <p>
        (iii) For any SMS message delivery failures;
      </p>
      
      <p>
        (iv) Any lost, late or misdirected computer transmission or network,
        electronic failures
      </p>
      
      <p>
        or any kind of any failure to receive entries owing to transmission failures
        or due to
      </p>
      
      <p>
        any technical reason;
      </p>
      
      
      <p>
        (v) Non-receipt of call/message due to registration of a Participant to the
        DND of the
      </p>
      
      <p>
        telecom provider/ National Do Not Call Registry/ any other restriction which
        a
      </p>
      
      <p>
        Participant has specifically requested for not receiving messages for the
        specific
      </p>
      
      <p>
        campaign;
      </p>
      
      <p>
        (vi) Other conditions beyond its control.
      </p>
      
      <p>
        p. Any Participant can win a maximum of 2 prize(s) during the Contest Period
        basis a
      </p>
      
      <p>
        combination of valid unique mobile number +valid batch code, unique
        permanent
      </p>
      
      <p>
        account number, unique Aadhaar number i.e. any participant can win a maximum
        of one
      </p>
      
      <p>
        cashback voucher and weekly or mega reward. Any participant who has won a
        cashback
      </p>
      
      <p>
        prize or Cashback voucher of Rs.9999 shall not be eligible to win
        Rs.1,00,000 cashback
      </p>
      
      <p>
        Voucher.
      </p>
      
      <p>
        q. The prize cannot be transferred, exchanged, or redeemed for cash. In the
        event that a
      </p>
      
      <p>
        Winner does not take the prize in the time and manner stipulated, then the
        prize will be
      </p>
      
      <p>
        forfeited and cash will not be awarded in lieu of the prize. Any unclaimed
        prize is not-
      </p>
      
      <p>
        transferable, non-refundable and non-redeemable for other goods or services.
      </p>
      
      <p>
        r. Cargill and/or Premier Sales Promotions Private Limited shall not be
        liable for any loss
      </p>
      
      <p>
        or damage of the prize due to act of god, governmental action, force majeure
      </p>
      
      <p>
        circumstances and / or any other reasons which are beyond the control of
        Cargill and/or
      </p>
      
      <p>
        Premier Sales Promotions Private Limited, and under no circumstances,
        Cargill and/or
      </p>
      
      <p>
        Premier Sales Promotions Private Limited shall be liable to pay any
        compensation
      </p>
      
      <p>
        whatsoever for such losses.
      </p>
      
      <p>
        s. Once the Winners are selected, Premier Sales Promotions Private Limited
        shall process
      </p>
      
      <p>
        the distribution of prizes to the Winners.
      </p>
      
      <p>
        t. The cashback voucher winners will receive their cashback reward code and
        a weblink to
      </p>
      
      <p>
        claim their cashback prize.
      </p>
      
      <p>
        u. For Cashback voucher of Rs 100,000 and Rs 9999 cashback Voucher: Premier
        Sales
      </p>
      
      <p>
        Promotions Private Limited shall reach out to the winner. In the event the
        shortlisted
      </p>
      
      <p>
        Winner&amp;#39;s number is busy, unreachable, or he/she does not attend the
        call, upto two (2)
      </p>
      
      <p>
        more attempts will be made to reach the Participant on their winning mobile
        number. If
      </p>
      
      <p>
        even on the third attempt, the Participant does not attend the call, or is
        unreachable, the
      </p>
      
      <p>
        Participant&amp;#39;s participation in the Contest comes to an end and the
        Participant&amp;#39;s entry shall
      </p>
      
      <p>
        stand cancelled/forfeited and the Participant becomes ineligible to receive
        the prize.
      </p>
      
      <p>
        v. In case of a positive response from the shortlisted Winner, each such
        shortlisted Winner
      </p>
      
      <p>
        will be required to send the following documents and information:
      </p>
      
      <p>
        (i) Copies of Aadhaar Card  PAN Card mandatory
      </p>
      
      <p>
        (ii) No objection/claim certificate from the Winner after claiming prize
      </p>
      
      
      <p>
        The above (i) to (iii) are referred to as the “Participation Package” for
        the Winner.
      </p>
      
      <p>
        A Participant agrees to share the Participant Package with Cargill and/or
        Premier
      </p>
      
      <p>
        Sales Promotions Private Limited and/or their respective authorised
        representatives
      </p>
      
      <p>
        for the purpose of claiming the prize. The completed Participation Package
        needs
      </p>
      
      <p>
        to be sent by email to Premier Sales Promotions Private Limited at:
      </p>
      
      <p>
        win1@bigcity.in within 2 days of the call made/message sent by Premier Sales
      </p>
      
      <p>
        Promotions Private Limited and/or its authorised representative to the
        selected
      </p>
      
      <p>
        Winner.
      </p>
      
      <p>
        w. Upon receipt of the Participation Package, Cargill and/or Premier Sales
        Promotions
      </p>
      
      <p>
        Private Limited shall scrutinize all documents. If all the documents
        submitted are in order
      </p>
      
      <p>
        and valid then a Participant will be treated as a Confirmed Winner.
      </p>
      
      <p>
        x. No prize will be awarded if the information presented/submitted by the
        Participant(s) at
      </p>
      
      <p>
        the time of entering the Contest is not factually correct.
      </p>
      
      <p>
        y. The responsibility of ensuring that the Participation Package reaches the
        specified e-mail
      </p>
      
      <p>
        Id above lies solely with the Participants.
      </p>
      
      <p>
        z. In the event of death of the Confirmed Winner, no claim from the nominee
        or his/her
      </p>
      
      <p>
        legal heirs of the Confirmed Winner shall be entertained for receiving the
        prize.
      </p>
      
      <p>
        aa. The Winners must, at the request of CARGILL INDIA PRIVATE LIMITED and/or
        its
      </p>
      
      <p>
        authorised representatives, participate in all promotional activity (such as
        publicity and
      </p>
      
      <p>
        photography) surrounding the winning of the prize, free of charge, and they
        consent to
      </p>
      
      <p>
        Cargill using their name and image in promotional material.
      </p>
      
      <p>
        bb. All right, title and interest, including but not limited to the
        intellectual property rights, in
      </p>
      
      <p>
        the promotional material(s) and in any and all responses received shall vest
        solely and
      </p>
      
      <p>
        exclusively with Cargill at all times. Cargill shall be entitled to use the
        responses received
      </p>
      
      <p>
        or any information in connection with the entry in any media for future
        promotional,
      </p>
      
      <p>
        marketing, publicity and any other purpose, without any permission and or
        payment to
      </p>
      
      <p>
        the Participant.
      </p>
      
      <p>
        cc. All rights including the intellectual property rights in the material
        submitted in
      </p>
      
      <p>
        connection with the Contest (whether written, audio, electronic or visual
        form, or a
      </p>
      
      <p>
        combination of those) or rights in any photographs, video and/or film
        footage and/or
      </p>
      
      <p>
        audio recording taken of Participants shall be owned by and belong to
        Cargill, and if
      </p>
      
      <p>
        these are not owned or doesn’t belong to Cargill, Participants
        unconditionally and
      </p>
      
      <p>
        irrevocably assigned them to Cargill exclusively.
      </p>
      
      <p>
        dd. All the participants shall be required to ensure that the mobile
        number(s), e-mail address
      </p>
      
      <p>
        and/or other details provided by them via the website or otherwise are true,
        accurate and
      </p>
      
      <p>
        in use. Any liability, consequence or claim arising on account of any
        incorrect or outdated
      </p>
      
      <p>
        information shall solely be borne by the affected/concerned participants.
        Cargill shall not
      </p>
      
      <p>
        be liable for failure of the winner to redeem the prize owing to incorrect
        details.
      </p>
      
      
      <p>
        ee. Cargill or it’s partner agency shall at no time be required to verify
        the accuracy and/or
      </p>
      
      <p>
        correctness of the information so provided by the participants. Further, you
        are
      </p>
      
      <p>
        responsible for maintaining the confidentiality of your mobile phones,
        e-mail accounts
      </p>
      
      <p>
        and passwords.
      </p>
      
      <p>
        ff. Cargill or it’s partner agency accepts no responsibility for any tax
        implications that may
      </p>
      
      <p>
        arise from the prize winnings. Tax is to be borne by the winner. Winners
        will have to bear
      </p>
      
      <p>
        incidental costs if any, that may arise for redemption of the Prizes.
      </p>
      
      <p>
        gg. In no event shall the maximum liability of Premier Sales Promotions Pvt
        Ltd or Cargill
      </p>
      
      <p>
        exceed the value of the prize.
      </p>
      
      
      <p>
      <strong>Reward Terms  Conditions</strong>
      </p>
      
      
      <p>
        MyGlamm Voucher Terms  Conditions
      </p>
      
      <p>
      <strong>a) Steps to Redeem:</strong>
      </p>
      
      <p>
      <strong> Visit the link- https://www.myglamm.com/</strong>
      </p>
      
      <p>
         Add products to the cart.
      </p>
      
      <p>
         Apply Promo Code
      </p>
      
      <p>
         Avail the discount and proceed to pay
      </p>
      
      <p>
      <strong>b) Terms  conditions-</strong>
      </p>
      
      <p>
        1. Offer is applicable One time per user.
      </p>
      
      <p>
        2. Offer valid sitewide.
      </p>
      
      <p>
        3. No shipping charges applicable
      </p>
      
      <p>
        4. Not valid for use with other promos on the App/ Website
      </p>
      
      <p>
        5. Minimum purchase Value should be Rs.650 to avail the discount.
      </p>
      
      <p>
        6. Two offers/promo code cannot be clubbed.
      </p>
      
      <p>
        7. For any queries related to orders, please reach out to hello@myglamm.com
      </p>
      
      
      <p>
        Cashback Voucher Terms  Conditions
      </p>
      
      <p>
        a) Cashback Voucher entitles the winner to avail cashback on their UPI or
        NEFT. The user
      </p>
      
      <p>
        will be responsible for their choice of the cashback transfer method.
      </p>
      
      <p>
        b) Any user purchasing a promotional product pack shall stand a chance to
        receive a
      </p>
      
      <p>
        cashback of an amount between Rs 10/- up to Rs 100000/- (“Cashback/Voucher
      </p>
      
      <p>
        Amount”).
      </p>
      
      <p>
        c) The user will be responsible for entering details such as UPI ID, etc.
        Cargill or Premier
      </p>
      
      <p>
        Sales Promotions Pvt Ltd. will not be responsible for any incorrect entries
        or details on
      </p>
      
      <p>
        the promotion website. No change in details will be entertained once you
        have submitted
      </p>
      
      <p>
        the booking.
      </p>
      
      <p>
        d) Premier Sales Promotions Private Limited or Cargill will not be
        responsible for cashback
      </p>
      
      <p>
        failures due to any failure at the payment gateway or payment processing
        partner end.
      </p>
      
      <p>
        e) The Payment Processing Partner holds the right to refuse transactions
        deemed fraudulent
      </p>
      
      <p>
        as per their policies.
      </p>
      
      
      <p>
        f) In no event shall Cargill be held liable for any issues relating to the
        redemption of the
      </p>
      
      <p>
        voucher/unique code including redemption requests not being honoured by the
        payment
      </p>
      
      <p>
        processing partner.
      </p>
      
      <p>
        g) The payment processing/wallet partner reserves the right to restrict
        cashback for any
      </p>
      
      <p>
        account with suspicious behaviour or invalid details/credentials. The wallet
        partner has
      </p>
      
      <p>
        the right to ask for documents to prove the retailer/user&amp;#39;s
        identity.
      </p>
      
      <p>
        h) Once user can avail benefit of maximum of 2 cashback vouchers
      </p>
      
      <p>
        i) If the coupon code is not visible due to improper scratch, Cargill shall
        have no liability
      </p>
      
      <p>
        j) Offer cannot be combined with any other promotion offered
      </p>
      
      <p>
        The voucher is valid only if stock purchased from our authorized trade
        partnerVoucher may
      </p>
      
      <p>
        be redeemed only once per user and cannot be assigned and is non-negotiable.
      </p>
      
      <p>
        Steps to Redeem:
      </p>
      
      <p>
        a)Enter Voucher Code, Name, Mobile number, Email ID, and click on Submit to
        complete
      </p>
      
      <p>
        the registration.
      </p>
      
      <p>
        b)Enter the OTP received on your registered mobile number and submit.Choose
        the
      </p>
      
      <p>
        preferred mode of cashback, enter the details and submit.
      </p>
      
      <p>
        c)You will receive your cashback in your selected mode within 24-48 business
        hours.
      </p>
      
      
      <p>
      <strong>2. General Terms  Conditions</strong>
      </p>
      
      <p>
        a. Each Participant represents and warrants that he/she is legally competent
        to enter into
      </p>
      
      <p>
        binding contracts under applicable laws. By taking part and/or entering into
        the Contest
      </p>
      
      <p>
        the Participant warrants that all information provided by the Participant
        regarding
      </p>
      
      <p>
        Participant&amp;#39;s name, age, state, city, address, phone number, etc.,
        is true, correct, accurate
      </p>
      
      <p>
        and complete.
      </p>
      
      <p>
        b. CARGILL INDIA PRIVATE LIMITED and/or Premier Sales Promotions Private
        Limited
      </p>
      
      <p>
        shall not be accountable/liable for any disruptions/stoppages/interruptions
        or cancellation
      </p>
      
      <p>
        of the Contest due to any government restrictions.
      </p>
      
      <p>
        c. Failure to exercise or delay in exercising a right or remedy provided
        hereunder or by law
      </p>
      
      <p>
        does not constitute a waiver of the right or remedy or waiver of other
        rights or remedies
      </p>
      
      <p>
        on the part of Cargill and/or Premier Sales Promotions Private Limited.
      </p>
      
      <p>
        d. If a Participant is dissatisfied with the Contest or the Contest rules
        and/or any content or
      </p>
      
      <p>
        any requirements of the Contest form, the materials available related
        thereto, or with
      </p>
      
      <p>
        these General Terms  Conditions and/or any terms and conditions
        associated with the
      </p>
      
      <p>
        Contest, his/her sole and exclusive remedy is to not participate in the
        Contest.
      </p>
      
      <p>
        e. Subject to eligibility criteria of the Contest, if a person chooses to
        access the Contest
      </p>
      
      <p>
        Website from outside India, he/she shall do so on his/her own initiative and
        shall be
      </p>
      
      <p>
        responsible for compliance with applicable local laws of the country.
      </p>
      
      <p>
        f. Participation in the Contest is purely voluntary and the same shall be
        construed as an
      </p>
      
      <p>
        acceptance of the terms and conditions stipulated herein.
      </p>
      
      
      <p>
        g. Validity of prize cannot be extended, OR new prize cannot be provided
        against the
      </p>
      
      <p>
        expired/unused codes.
      </p>
      
      <p>
        h. All disputes arising out of or in connection to the Contest are subject
        to exclusive
      </p>
      
      <p>
        jurisdiction of the courts in Gurgaon, India only.
      </p>

    </Popup>
  );
};

export default TermsPopup;
